.container {
  padding: 2rem;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  font-family: var(--font-primary);
  line-height: 1.8;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.backButton {
  position: absolute;
  top: 2rem;
  left: 20px;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: #000;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 8px 12px;
  transition: color 0.3s ease;
  z-index: 1;
}

.backButton span {
  margin-left: 0.4rem;
  font-size: 0.8rem;
}

.backButton:hover {
  text-decoration: none;
  cursor: pointer;
}

.backButton svg {
  margin-right: 8px;
}

.icon {
  font-size: 18px;
}

.title {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 3rem;
  font-family: var(--font-heading);
  font-weight: 700;
}

.image {
  width: 100%;
  height: auto;
  max-height: 480px;
  min-height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 2rem;
}

.content {
  font-size: 1.1rem;
  line-height: 1.8;
  text-align: justify;
}

.paragraph {
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .container {
    padding: 3rem 1rem;
  }

  .title {
    font-size: 1.5rem;
  }

  .image {
    height: auto;
    margin-bottom: 1rem;
  }

  .content {
    font-size: 0.9rem;
  }

  .backButton {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 1.25rem;
  }

  .content {
    font-size: 0.85rem;
  }

  .backButton {
    font-size: 16px;
    top: 1rem;
  }
}