.container {
    padding: 4rem 2rem;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    font-family: var(--font-primary);
    line-height: 1.8;
}

.content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    position: relative;
}

.textSection {
    flex: 1.25;
    max-width: 768px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.heading {
    font-size: 3rem;
    text-align: center;
    font-weight: 700;
    color: var(--heading-color);
    margin-bottom: 2rem;
    font-family: var(--font-heading);
}

.description {
    font-size: 1.1rem;
    color: var(--text-color);
    font-weight: 400;
    text-align: center;
    margin-bottom: 2rem;
    line-height: 2;
}

.button {
    color: var(--button-bg);
    border: 2px solid var(--button-bg);
    background-color: var(--background-color);
    border-radius: var(--radius-md);
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
}

.button:hover {
    background-color: var(--button-bg);
    color: var(--background-color);
}

.imageSection {
    flex: 1;
    max-width: 500px;
    text-align: center;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.image {
    max-width: 100%;
    height: 100%;
    min-height: 300px;
    object-fit: cover;
    transition: transform 0.5s ease;
}

.imageSection:hover .image {
    transform: scale(1.1);
}

@media (max-width: 992px) {
    .content {
        flex-direction: column-reverse;
        gap: 1rem;
    }

    .textSection,
    .imageSection {
        max-width: 100%;
    }

    .description {
        font-size: 1rem;
        text-align: justify;

    }

    .heading {
        font-size: 2rem;
    }

    .imageSection {
        flex: 1;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .heading {
        font-size: 2.5rem;
    }

    .description {
        font-size: 1rem;
        line-height: 1.8;
    }

}

@media (max-width: 480px) {
    .container {
        padding: 2rem 1rem;
    }

    .heading {
        font-size: 1.8rem;
    }

    .textSection,
    .imageSection {
        padding: 0rem;
    }
}