.hero {
  display: flex;
  text-align: center;
  background: url("/public/assets/images/internship/hero.jpg") no-repeat center top / cover;
  height: 100vh;
  position: relative;
}

.hero::before {
  content: '';
  position: absolute;
  inset: 0;
  background: rgba(1, 41, 143, 0.1);
  z-index: 1;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  gap: 30px;
  position: relative;
  z-index: 2;
}

.backButton {
  position: absolute;
  top: 1rem;
  left: 20px;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: var(--background-color);
  font-size: 1.5rem;
  cursor: pointer;
  padding: 8px 12px;
  transition: color 0.3s ease;
  z-index: 1;
}

.backButton span {
  margin-left: 0.4rem;
  font-size: 0.8rem;
}

.backButton:hover {
  text-decoration: none;
  cursor: pointer;
}

.backButton svg {
  margin-right: 8px;
}

.textContent {
  position: absolute;
  right: 12rem;
  top: 10%;
  text-align: right;
}

.heading {
  font-size: 5rem;
  font-weight: 500;
  margin-bottom: 10px;
  color: var(--background-color);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.button {
  margin-top: 20px;
  padding: 12px 30px;
  font-size: 1.1rem;
  color: var(--button-bg);
  background-color: var(--background-color);
  border: none;
  border-radius: var(--radius-sm);
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.button:hover {
  background-color: var(--button-hover-bg);
  color: var(--background-color);
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
}

.image {
  max-width: 100%;
  max-height: 600px;
  border-radius: 10px;
}

.scrollIcon {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  animation: bounce 2s infinite;
  z-index: 999;
}

.iconText {
  color: var(--background-color);
  margin-bottom: 0.5rem;
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(-50%) translateY(0);
  }

  40% {
    transform: translateX(-50%) translateY(-10px);
  }

  60% {
    transform: translateX(-50%) translateY(-5px);
  }
}

@media (max-width: 768px) {
  .hero {
    padding: 0 30px;
  }

  .contentWrapper {
    gap: 20px;
  }

  .textContent {
    position: static;
    text-align: center;
  }

  .heading {
    font-size: 2.5rem;
  }

  .button {
    padding: 8px 20px;
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .contentWrapper {
    gap: 15px;
  }

  .heading {
    font-size: 2rem;
  }

  .button {
    padding: 6px 18px;
    font-size: 0.85rem;
  }

  .image {
    max-width: 90%;
  }
}