.container {
  padding: 2.5rem;
  background-color: #fff;
  font-family: 'Arial', sans-serif;
  line-height: 1.8;
  color: #333;
  width: 100%;
  min-height: 100vh;
  max-width: 1800px;
  margin: 0 auto;
}

.header {
  margin-bottom: 40px;
  text-align: center;
}

.header h1 {
  font-size: 1.8rem;
  margin-bottom: 5px;
  font-weight: 600;
  color: #222;
}

.header p {
  font-size: 14px;
  color: #666;
}

.section {
  margin-bottom: 25px;
  padding: 1.2rem;
  border-bottom: 1px solid #ddd;
  background: #f9f9f9;
  border-radius: 6px;
}

.section:last-child {
  border-bottom: none;
}

.section h2 {
  font-size: 1.3rem;
  margin-bottom: 12px;
  font-weight: 600;
  color: #000;
  border-left: 4px solid #0078e7;
  padding-left: 10px;
}

.section p {
  font-size: 1rem;
  margin-bottom: 10px;
}

.section ul {
  margin-left: 1.8rem;
  list-style-type: disc;
  padding-left: 0.5rem;
}

.section ul li {
  margin-bottom: 6px;
}

.link {
  color: #0078e7;
  text-decoration: none;
  font-weight: 500;
}

.link:hover {
  text-decoration: underline;
  color: #005bb5;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .container {
    padding: 1.5rem;
  }
  
  .header h1 {
    font-size: 1.5rem;
  }

  .section {
    padding: 1rem;
  }

  .section h2 {
    font-size: 1.1rem;
    padding-left: 8px;
  }

  .section ul {
    margin-left: 1.2rem;
  }
}
