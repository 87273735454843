.container {
  padding: 2rem;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  font-family: var(--font-primary);
  line-height: 1.8;
}

.heading {
  font-size: 3rem;
  text-align: center;
  font-weight: 700;
  color: var(--heading-color);
  margin-bottom: 2rem;
  font-family: var(--font-heading);
}

.contentWrapper {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
}

.listContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;
}

.item {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  color: var(--text-color);
  padding: 20px;
  border-radius: 8px;
  transition: all 0.3s ease;
  background: #ffffff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  font-weight: 400;
}

.item:hover {
  transform: translateY(-5px);
}

.number {
  font-size: 2rem;
  font-weight: bold;
  color: var(--button-bg);
  background: rgba(0, 123, 255, 0.1);
  padding: 0 1rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 56px;
}

.text {
  font-size: 1.1rem;
  line-height: 1.6;
  padding-left: 0.5rem;
}

.subListContainer {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-content: flex-start;
  line-height: 1.8;
}

.imageContainer {
  flex: 0.6;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.image {
  width: 100%;
  max-width: 550px;
  max-height: 672px;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1224px) {
  .contentWrapper {
    flex-direction: column-reverse;
  }

  .imageContainer {
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 2.5rem;
  }

  .text {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 2rem 1rem;
  }

  .heading {
    font-size: 2rem;
  }

  .number {
    font-size: 1.5rem;
  }
}
