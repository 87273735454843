.container {
  padding: 4rem 0;
  width: 100%;
  /* max-width: 1600px; */
  margin: 0 auto;
  font-family: var(--font-primary);
  line-height: 1.8;
}

.content {
  /* max-width: 1400px; */
  margin: 0 auto;
  text-align: center;
  border-radius: 1.25rem;
  margin-top: 2rem;
}

.heading {
  font-size: 3rem;
  text-align: center;
  font-weight: 700;
  color: var(--heading-color);
  margin-bottom: 2rem;
  font-family: var(--font-heading);
}

.imageContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 2rem 0;
}

.image {
  width: 100%;
  height: 100%;
  max-height: 800px;
  object-fit: top;
  /* border-radius: 20px; */
}

.overlay {
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-35%, -50%);
  text-align: center;
  /* background: rgba(0, 0, 0, 0.5); */
  padding: 2rem;  
  border-radius: 1rem;
  color: #fff;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.launchText {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 2rem;
  /* text-transform: capitalize; */
  font-family: var(--font-heading);
  color: var(--ivory-color);
  letter-spacing: 2px;
}

.icons {
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-bottom: 2rem;
}

.storeIcon {
  width: 48px;
  height: 48px;
  transition: transform 0.3s ease-in-out;
  color: var(--background-color);
}

/* .storeIcon:hover {
  transform: scale(1.1);
  color: var(--font-primary);
} */

.stayTuned {
  font-size: 1.75rem;
  font-weight: 400;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .heading {
    font-size: 2.8rem;
  }

  .launchText {
    font-size: 2rem;
  }

  .storeIcon {
    width: 45px;
    height: 45px;
  }

  .stayTuned {
    font-size: 1.5rem;
  }

  .overlay {
    width: 85%;
    padding: 1.8rem;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 2.5rem;
  }

  .overlay {
    width: 90%;
    padding: 1.5rem;
  }

  .imageContainer {
    padding: 0;
  }

  .launchText {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  .icons {
    margin-bottom: 1rem;
  }

  .storeIcon {
    width: 32px;
    height: 32px;
  }

  .stayTuned {
    font-size: 1rem;
  }

  .image {
    min-height: 400px;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 2rem 0;
  }

  .heading {
    font-size: 2rem;
  }

  .overlay {
    width: 95%;
    padding: 1.2rem;
  }

  .launchText {
    font-size: 1.5rem;
  }

  .storeIcon {
    width: 30px;
    height: 30px;
  }

  .stayTuned {
    font-size: 0.9rem;
  }

  .image {
    min-height: 350px;
  }
}