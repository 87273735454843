.container {
  padding: 4rem 2rem;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  font-family: var(--font-primary);
  line-height: 1.8;
}

.heading {
  font-size: 3rem;
  text-align: center;
  font-weight: 700;
  color: var(--heading-color);
  margin-bottom: 2rem;
  font-family: var(--font-heading);
}

.teamsContainer {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  justify-content: center;
  align-items: flex-start;
  max-width: 1400px;
  margin: 0 auto;
}

.teamCard {
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  padding: 1rem;
}

.icon {
  background: #ffffff;
  padding: 2rem 1rem;
  width: 100%;
  max-width: 140px;
  height: auto;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.icon img {
  width: 100%;
  max-width: 60px;
  height: auto;
}

.title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--text-color);
  word-wrap: break-word;
}

.description {
  font-size: 1.1rem;
  color: var(--text-color);
  word-wrap: break-word;
  font-weight: 400;
}

@media (max-width: 768px) {
  .teamsContainer {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 1.5rem;
  }

  .heading {
    font-size: 2.5rem;
  }

  .teamCard {
    padding: 1rem;
  }

  .title {
    font-size: 1.1rem;
  }

  .description {
    font-size: 0.9rem;
  }
}


@media (max-width: 480px) {
  .container {
    padding: 2rem 1rem;
  }

  .heading {
    font-size: 2rem;
  }
}