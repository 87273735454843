.contactContainer {
  display: flex;
  flex-direction: row;
  background: var(--imperial-blue);
  color: white;
  padding: 4rem 8rem 2rem 8rem;
  align-items: stretch;
  justify-content: space-between;
  margin: auto;
  font-family: var(--font-primary);
  gap: 40px;
  line-height: 1.8;
}

.contactDetails {
  flex: 1;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.heading {
  font-size: 2rem;
  font-weight: 500;
  color: var(--ivory-color);
  margin-bottom: 1rem;
  font-family: var(--font-heading);
  letter-spacing: 0.5px;
}

.email strong,
.address strong {
  font-weight: 600;
  color: var(--ivory-color);
}

.email,
.address {
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height: 1.5;
  font-weight: 300;
  color: var(--ivory-color);
}

.social {
  display: flex;
  align-items: center;
  gap: 15px;
}

.linkedinIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-left: -0.5rem;
  font-size: 22px;
  color: var(--ivory-color);
  border-radius: 50%;
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
}

.linkedinIcon:hover {
  color: #ccc;
}

/* Divider */
.divider {
  width: 1px;
  background: white;
  height: auto;
  min-height: 220px;
  margin: auto 20px;
}

/* Map Section */
.mapSection {
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

.map {
  width: 100%;
  max-width: 450px;
  height: 250px;
  margin: auto;
  border-radius: 20px;
  border: none;
}

.map iframe {
  border-radius: 20px;
  width: 100%;
  height: 100%;
}

/* Responsiveness */
@media (max-width: 1200px) {
  .contactContainer {
    padding: 4rem 4rem;
    gap: 20px; /* Reduce spacing */
  }

  .email,
  .address {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .contactContainer {
    flex-direction: column;
    text-align: center;
    padding: 20px;
    gap: 30px;
  }

  .contactDetails {
    padding-right: 0;
    margin-bottom: 20px;
  }

  .email,
  .address {
    font-size: 16px;
  }

  .divider {
    display: none;
  }

  .map {
    max-width: 100%;
    height: 200px;
  }

  .social {
    justify-content: center;
    gap: 12px;
  }

  .linkedinIcon {
    width: 35px;
    height: 35px;
    font-size: 18px;
  }

  .heading {
    font-size: 2rem;
  }
}
