@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleyway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400;700;900&display=swap');

:root {
  /* Colors */
  --primary-color: #1e3a8a;
  --secondary-color: #9333ea;
  --accent-color: #f59e0b;
  --ivory-color: #fffff0;
  --imperial-blue: #002147;
  --background-color: #f9fafb;
  --text-color: #002147;
  --text-light: #6b7280;
  --border-color: #e5e7eb;
  --button-bg: #1e3a8a;
  --button-hover-bg: rgb(0, 57, 200);
  --nav-bg: rgba(0, 0, 0, 0.95);
  --slate-gray: #2e2e2e;
  --amber-yellow: #ffbf00;
  --heading-color: #002147;

  /* Gradients */
  --primary-gradient: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  --accent-gradient: linear-gradient(135deg, var(--accent-color), var(--secondary-color));

  /* Typography */
  --font-primary: 'Source Sans 3', sans-serif;
  --font-heading: 'Playfair Display', sans-serif;
  --font-heading_tagline: "Playfair Display SC", serif;
  --font-subheading: 'Raleway', sans-serif;
  --font-button: 'Source Sans 3', sans-serif;

  /* Font Sizes */
  --text-xs: 12px;
  --text-sm: 14px;
  --text-base: 16px;
  --text-lg: 18px;
  --text-xl: 20px;
  --text-2xl: 24px;
  --text-3xl: 30px;
  --text-4xl: 36px;
  --text-5xl: 48px;

  /* Spacing */
  --space-xs: 4px;
  --space-sm: 8px;
  --space-md: 16px;
  --space-lg: 24px;
  --space-xl: 32px;
  --space-2xl: 48px;
  --space-3xl: 64px;

  /* Border Radius */
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-lg: 12px;
  --radius-xl: 16px;
  --radius-xxl: 24px;

  /* Shadows */
  --shadow-sm: 0px 1px 3px rgba(0, 0, 0, 0.1);
  --shadow-md: 0px 3px 6px rgba(0, 0, 0, 0.15);
  --shadow-lg: 0px 5px 15px rgba(0, 0, 0, 0.2);

  /* Transition */
  --transition: all 0.3s ease-in-out;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-sans);
  font-size: var(--text-base);
  color: var(--text-color);
  background-color: var(--ivory-color);
  line-height: 1.6;
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  line-height: 1.2;
}

h1 { font-size: var(--text-5xl); }
h2 { font-size: var(--text-4xl); }
h3 { font-size: var(--text-3xl); }
h4 { font-size: var(--text-2xl); }
h5 { font-size: var(--text-xl); }
h6 { font-size: var(--text-lg); }

/* Links */
a {
  color: var(--primary-color);
  text-decoration: none;
  transition: var(--transition);
}

a:hover {
  color: var(--secondary-color);
}

/* Buttons */
button {
  padding: var(--space-sm) var(--space-md);
  border: none;
  border-radius: var(--radius-lg);
  cursor: pointer;
  transition: var(--transition);
  font-family: var(--font-button);
  font-size: 14px;
}

button.primary {
  background: var(--primary-gradient);
  color: white;
  box-shadow: var(--shadow-md);
}

button.secondary {
  background: var(--accent-gradient);
  color: white;
}

/* Forms */
input, textarea {
  width: 100%;
  padding: var(--space-sm);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-sm);
  font-size: var(--text-base);
  transition: var(--transition);
}

input:focus, textarea:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: var(--shadow-sm);
}

/* Responsive Grid */
.container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  font-family: var(--font-primary);
}

.grid {
  display: grid;
  gap: var(--space-md);
}

.grid2 { grid-template-columns: repeat(2, 1fr); }
.grid3 { grid-template-columns: repeat(3, 1fr); }
.grid4 { grid-template-columns: repeat(4, 1fr); }

/* Responsive Breakpoints */
@media (max-width: 1024px) {
  .grid3, .grid4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .grid2, .grid3, .grid4 {
    grid-template-columns: 1fr;
  }

  h1 { font-size: var(--text-4xl); }
  h2 { font-size: var(--text-3xl); }
  h3 { font-size: var(--text-2xl); }
}

.heading {
  font-size: 2.5rem;
  text-align: center;
  font-weight: 500;
  color: var(--text-color);
}

.subheading {
  font-size: 1.2rem;
  color: var(--text-color);
  margin-bottom: 2rem;
  font-weight: 300;
  text-align: center;
}

@media (max-width: 768px) {
  .heading {
    font-size: 2rem;
  }

  .subheading {
    font-size: 1.1rem;
  }
}
