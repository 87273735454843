.container {
  padding: 6rem 2rem;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  font-family: var(--font-primary);
  line-height: 1.8;
}

.heading {
  font-size: 3rem;
  text-align: center;
  font-weight: 700;
  color: var(--heading-color);
  margin-bottom: 2rem;
  font-family: var(--font-heading);
}

.subheading {
  font-size: 1.1rem;
  color: var(--text-color);
  margin-bottom: 2rem;
  font-weight: 600;
  text-align: center;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  font-family: var(--font-subheading);
}

.highlight {
  color: var(--text-color);
  font-weight: bold;
}

.description {
  font-size: 1.1rem;
  color: var(--text-color);
  font-weight: 400;
  text-align: justify;
  margin-bottom: 2rem;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--space-xl);
}

.card {
  border-radius: var(--radius-xl);
  padding: 1.5rem 3rem;
  text-align: center;
  flex: 1 1 calc(45% - 2rem);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border: 1px solid var(--imperial-blue);
  border-bottom-width: 4px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.cardHeading {
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--text-color);
  font-family: var(--font-heading);
  letter-spacing: 0.5px;
}

.cardDescription {
  font-size: 1.1rem;
  color: var(--text-color);
  font-weight: 400;
  line-height: 1.8;
  text-align: justify;
}

@media (max-width: 1024px) {
  .contentContainer {
    flex-direction: column-reverse;
    gap: 2rem;
  }

  .imageContainer {
    max-width: 100%;
    max-height: 360px;
  }

  .visionMissionContainer {
    flex-direction: column;
    gap: 2rem;
  }

  .card {
    flex: 1 1 100%;
    padding: 2.5rem 1.5rem;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 2.5rem;
  }

  .subheading {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .description {
    font-size: 1rem;
  }

  .card {
    padding: 2rem 1rem;
  }

  .cardHeading {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 2rem 1rem;
  }

  .heading {
    font-size: 2rem;
  }

  .subheading {
    width: 100%;
    text-align: justify;
  }

  .description {
    line-height: 1.8;
  }

  .card {
    padding: 1.5rem 1rem;
  }

  .cardHeading {
    font-size: 1.4rem;
  }

  .cardDescription {
    font-size: 1rem;
  }
}