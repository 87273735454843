.container {
  padding: 1rem 2rem 4rem 2rem;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  font-family: var(--font-primary);
  line-height: 1.8;
}

.heading {
  font-size: 3rem;
  text-align: left;
  font-weight: 700;
  color: var(--heading-color);
  margin-bottom: 2rem;
  font-family: var(--font-heading);
}

.list {
  list-style-type: disc;
  padding-left: 1rem;
  font-size: 1.1rem;
  color: var(--text-color);
  margin: 0 auto;
  font-weight: 400;
}

@media (max-width: 768px) {
  .heading {
    font-size: 2.5rem;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 1rem 2rem 1rem 1rem;
  }

  .heading {
    font-size: 2rem;
    text-align: center;
  }

  .list {
    font-size: 1rem;
  }
}
