.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.title {
  font-size: 2rem;
  color: #ffffff;
  margin-bottom: 1rem;
}

.progressBarContainer {
  width: 90%;
  max-width: 400px;
  height: 8px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  overflow: hidden;
  margin-top: 1rem;
}

.progressBar {
  height: 100%;
  background: linear-gradient(to right, #ff6f91, #ff9671);
  transition: width 0.3s ease-in-out;
}

.loadingText {
  font-size: 1rem;
  color: #ffffff;
  margin-top: 0.5rem;
}

@media (max-width: 480px) {
  .title {
    font-size: 1.5rem;
  }
  .progressBarContainer {
    width: 80%;
  }
  .loadingText {
    font-size: 0.9rem;
  }
}