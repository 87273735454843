@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.container {
  background: var(--ivory-color);
  font-family: var(--font-primary);
  padding: 4rem 0 5rem 0;
  margin-bottom: 2rem;
}

.newsletterContent {
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  padding: 48px;
  border-radius: 10px;
  text-align: center;

}

.heading {
  font-size: 3rem;
  text-align: center;
  font-weight: 700;
  color: var(--text-color);
  margin-bottom: 4rem;
  font-family: var(--font-heading);
}

.form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 500px;
  margin: 0 auto;
}

.input {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 40px;
  font-size: 16px;
  outline: none;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  background: var(--ivory-color);
  box-shadow: var(--shadow-sm);
  font-family: var(--font-button);
}

.checkboxContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* gap: 10rem; */
  font-size: 14px;
  color: #444;
  margin-bottom: 12px;
  max-width: 500px;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  padding-left: 10px;
  font-family: var(--font-button);
  color: var(--imperial-blue);
  font-size: 16px;
  font-weight: 400;
  max-width: 500px;
  width: 100%;

}

.checkbox {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border-radius: 20px;
}

.subscribeButton, .disabledButton {
  background-color: var(--imperial-blue);
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid var(--button-bg);
  border-radius: 50px;
  padding: 12px 90px;
  cursor: pointer;
  transition: background 0.3s;
  max-width: 308px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 67px;
  margin: 0 auto;
  font-family: var(--font-button);
  font-weight: 600;
}

.disabledButton {
  background-color: #bbb;
  color: #000;
  cursor: not-allowed;
  border: 1px solid #bbb;
}

.subscribeButton:hover {
  background-color: var(--button-hover-bg);
}

.disabledButton:hover {
  background-color: #aaa;
}

.link {
  color: #154b7d;
  text-decoration: none;
}

.link:hover {
  color: #2270b9
}

@media (max-width: 600px) {
  .container {
    width: 100%;
    padding: 2rem 1rem;
  }

  .heading {
    font-size: 2rem;
  }

  .newsletterContent {
    padding: 16px;
  }

  .checkboxContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding-left: 0.5rem;
  }

  .checkboxLabel {
    font-size: 16px;
  }
}
