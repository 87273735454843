.container {
    padding: 4rem 2rem;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    font-family: var(--font-primary);
}

.heading {
    font-size: 3rem;
    text-align: center;
    font-weight: 700;
    color: var(--heading-color);
    margin-bottom: 2rem;
    font-family: var(--font-heading);
}

.imageSection {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media (max-width: 768px) {
    .heading {
        font-size: 2.5rem;
    }
}

@media (max-width: 480px) {
    .container {
        padding: 2rem 1rem;
    }

    .heading {
        font-size: 2rem;
    }
}