.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  font-family: var(--font-primary);
  line-height: 1.8;
}

.content {
  display: flex;
  flex-direction: row;
  max-width: 1400px;
  width: 100%;
  gap: 40px;
  align-items: center;
}

.imageSection {
  flex: 1.25;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.image {
  width: 100%;
  height: auto;
  min-height: 600px;
  object-fit: cover;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s ease;
}

.imageSection:hover .image {
  transform: scale(1.1);
}

.textContainer {
  flex: 1.5;
  padding: 40px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.heading {
  font-size: 3rem;
  text-align: center;
  font-weight: 700;
  color: var(--heading-color);
  margin-bottom: 2rem;
  font-family: var(--font-heading);
}

.features {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.featureItem {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  padding: 16px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.featureItem:hover {
  background: rgba(0, 0, 0, 0.05);
}

.icon {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
}

.featureTitle {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 0.5rem;
}

.featureDescription {
  font-size: 1.1rem;
  color: var(--text-color);
  line-height: 1.6;
  font-weight: 400;
}

@media (max-width: 1200px) {
  .content {
    flex-direction: column;
  }

  .heading {
    font-size: 2rem;
  }
}

@media (max-width: 992px) {
  .content {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 2rem;
  }

  .featureTitle {
    font-size: 1.3rem;
  }

  .featureDescription {
    font-size: 1.1rem;
  }

  .textContainer {
    padding: 0;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 2rem 1rem;
  }

  .image {
    min-height: 100%;
  }

  .heading {
    font-size: 1.6rem;
    text-align: center;
  }

  .featureItem {
    padding: 10px;
  }

  .featureTitle {
    font-size: 1.1rem;
  }

  .featureDescription {
    font-size: 1rem;
  }
}