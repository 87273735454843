.careersContainer {
  font-family: var(--font-primary);
  padding-bottom: 2rem;
  background-color: #f9f9f9;
  min-height: 100vh;
}

.backButton {
  position: absolute;
  top: 1rem;
  left: 20px;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 8px 12px;
  transition: color 0.3s ease;
  z-index: 1;
}

.backButton span {
  margin-left: 0.4rem;
  font-size: 0.8rem;
}

.backButton:hover {
  text-decoration: none;
  cursor: pointer;
}

.backButton svg {
  margin-right: 8px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1a52e2;
  color: white;
  padding: 20px;
  height: 300px;
  margin-bottom: 1rem;
}

.header h2 {
  font-size: 2.5rem;
  font-weight: 500;
}

.backButton {
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.tabContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.tabButton {
  background: #f1f1f1;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: background 0.3s, color 0.3s;
}

.tabButton:hover {
  background: #ddd;
}

.activeTab {
  background: #1a52e2;
  color: white;
}

.searchFilter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 4rem 12rem;
}

.searchBarContainer {
  display: flex;
  align-items: center;
  flex: 1;
  background: white;
  padding: 6px;
  border-radius: 50px;
  border: 1px solid #ccc;
}

.searchIcon {
  margin-left: 10px;
  color: #999;
}

.searchBar {
  border: none;
  outline: none;
  flex: 1;
  padding: 0 10px;
}

.filterDropdown {
  position: relative;
}

.filterModal {
  position: absolute;
  top: 50px;
  right: 0;
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 1rem;
  z-index: 2000;
  max-width: 1200px;
  min-width: 300px;
}

.filterHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.filterHeader h3 {
  font-size: 16px;
  font-weight: bold;
}

.closeButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.filterContent label {
  display: block;
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}

.filterContent select {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.filterButton {
  color: #999;
  border: none;
  cursor: pointer;
  background: transparent;
  padding-right: 10px;
  display: flex;
  align-self: center;
  justify-content: center;
}

.jobGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
  justify-content: center;
  align-items: center;
}

.jobCard {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 360px;
  min-width: 320px;
  min-height: 460px;
  position: relative;
  padding-bottom: 60px;
}

.jobCardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.jobTitle {
  font-size: 24px;
  font-weight: 500;
}

.shareIcon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  z-index: 1000;
}

.jobDetails {
  display: flex;
  justify-content: space-between;
  color: #555;
  margin-bottom: 1rem;
  gap: 2px;
}

.jobDetails span {
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 6px;
}

.roleLabel {
  font-size: 0.9rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.roleDescription {
  font-size: 12px;
  text-align: justify;
  margin-bottom: 1.5rem;
  line-height: 1.5rem;
}

.progressContainer {
  margin-bottom: 1rem;
}

.progressBar {
  background: #ddd;
  height: 6px;
  border-radius: 4px;
  overflow: hidden;
}

.progressFill {
  background: #1a52e2;
  height: 100%;
}

.progressContainer span  {
  color: #000;
  font-size: 10px;
  font-weight: 400;
  text-align: left;
}

.applyButton {
  width: 60%;
  background: #1a52e2;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50px;
  cursor: pointer;
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
}

.applyButton:disabled {
  background: grey;
  cursor: not-allowed;
}

.applyButton:hover {
  background: #0438ba;
}

.applyButton:disabled:hover {
  background: grey;
}

@media (max-width: 768px) {

  .searchFilter {
    padding: 4rem 4rem 1rem 4rem;
  }

  .searchFilter {
    flex-direction: column;
    gap: 10px;
  }

  .searchBarContainer {
    width: 100%;
  }

  .filterModal {
    width: 90%; 
    padding: 1rem;
    margin-top: 1rem;
  }

  .filterModal h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .filterOptions {
    flex-direction: column;
    gap: 1rem;
  }

  .optionWrapper {
    gap: 10px; 
  }

  .filterModal label {
    font-size: 14px;
  }

  .filterModal select {
    font-size: 14px;
    padding: 6px;
  }

  .applyFiltersButton {
    width: 50%;
    font-size: 16px;
    padding: 8px 0;
    margin: 0  auto;
    margin-top: 1.5rem;
  }
}

@media (max-width: 480px) {
  .searchFilter {
    padding: 2rem 1rem 1rem 1rem;
  }
  .header h2 {
    font-size: 1.5rem;
  }

  .filterModal {
    min-width: 250px;
  }
}