.container {
  padding: 4rem 2rem;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  font-family: var(--font-primary);
  line-height: 1.8;
}

.heading {
  font-size: 3rem;
  text-align: center;
  font-weight: 700;
  color: var(--heading-color);
  margin-bottom: 2rem;
  font-family: var(--font-heading);
}

.sliderWrapper {
  position: relative;
  /* max-width: 1000px; */
  margin: 0 auto;
  overflow: visible;
}


.slick-slider {
  overflow: visible;
  gap: 2rem;
}

.slider {
  max-width: 1600px;
  margin: 0 auto;
  overflow: visible;
  padding-left: 2rem;
}

.card {
  position: relative;
  overflow: visible;
  text-align: center;
  padding: 1rem;
  border-radius: 1rem;
  cursor: pointer;
  min-height: 320px;
  max-width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #fffcf6;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  backdrop-filter: blur(10px);
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
  transition: transform 0.4s cubic-bezier(0.25, 1, 0.5, 1),
    box-shadow 0.4s ease,
    background 0.4s ease;
}

.avatar {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  transition: transform 0.5s cubic-bezier(0.25, 1, 0.5, 1), height 0.5s ease-in-out;
  height: 280px;
  width: auto;
  border-radius: 50%;
}


.infoWrapper {
  position: absolute;
  bottom: 8px;
  left: 3%;
  width: 94%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0.1rem;
  background-color: var(--ivory-color);
  border-radius: 1rem;
  transition: background-color 0.3s ease-in-out;
  box-shadow: var(--shadow-lg);
}

.card:hover .infoWrapper {
  background-color: #f0f0f0;
}

.name {
  flex: 1;
  font-size: 0.8rem;
  font-weight: 600;
  background-color: var(--imperial-blue);
  border-radius: 20px;
  padding: 0.3rem 0.5rem;
  color: var(--ivory-color);
  text-align: center;
}

.role {
  flex: 2;
  font-size: 0.8rem;
  color: var(--imperial-blue);
  font-weight: 400;
}

.linkedInIcon {
  flex: 0.5;
  font-size: 1.2rem;
  color: var(--imperial-blue);
  display: flex;
  align-items: center;
  transition: color 0.3s ease-in-out;
}

.linkedInIcon:hover {
  color: var(--button-bg);
}

.arrow {
  position: absolute;
  top: 50%;
  background: none;
  transform: translateY(-50%);
  color: var(--text-color);
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 2rem;
  z-index: 10;
  transition: background 0.3s ease-in-out;
}

.arrow:hover {
  color: #0e3c92;
}

.arrow.disabled {
  color: #ccc;
  cursor: unset;
}

.prev {
  left: -50px;
}

.next {
  right: -50px;
}

@media (max-width: 768px) {
  .heading {
    font-size: 2.5rem;
  }

  .sliderWrapper {
    padding: 1rem;
  }

  .prev {
    left: -30px;
  }

  .next {
    right: -30px;
  }

  .card {
    min-width: 240px;
    max-width: 240px;
  }

  .card:hover .avatar {
    height: 360px;
  }

  .role {
    font-size: 0.7rem;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 2rem 1rem;
  }

  .heading {
    font-size: 2rem;
  }
}

@media (max-width: 360px) {

  .sliderWrapper {
    padding: 1rem;
  }

  .card {
    min-width: 220px;
    max-width: 220px;
  }

  .avatar {
    height: 300px;
    width: auto;
  }

  .card:hover .avatar {
    height: 350px;
  }

  .role {
    font-size: 0.6rem;
  }
}