.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 2rem;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    font-family: var(--font-primary);
    line-height: 1.8;
    position: relative;
}

.heading {
    font-size: 3rem;
    text-align: center;
    font-weight: 700;
    color: var(--heading-color);
    margin-bottom: 2rem;
    font-family: var(--font-heading);
}


.subheading {
    font-size: 1.1rem;
    color: var(--text-color);
    margin-bottom: 2rem;
    font-weight: 600;
    text-align: center;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    font-family: var(--font-subheading);
}

.roadmapContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 80%;
}

.roadmapContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 6px;
    height: 100%;
    background: var(--imperial-blue);
    transform: translateX(-50%);
    z-index: 0;
    border-radius: 3px;
}

.roadmapItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 700px;
    margin: 40px 0;
    position: relative;
    z-index: 1;
    text-align: left;
}

.roadmapItem:nth-child(odd) {
    flex-direction: row-reverse;
    text-align: right;
}

.roadmapItem:nth-child(even) {
    flex-direction: row;
    text-align: left;
}

.roadmapItem:nth-child(odd) .roadmapContent {
    margin-left: auto;
}

.roadmapItem:nth-child(even) .roadmapContent {
    margin-right: auto;
}

.roadmapIcon {
    font-size: 65px;
    color: var(--imperial-blue);
    background: var(--ivory-color);
    border-radius: 50%;
    padding: 18px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin: 0 25px;
}

.roadmapContent {
    background: var(--ivory-color);
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.2);
    max-width: 350px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    position: relative;
}

/* .roadmapContent::after {
    content: "";
    position: absolute;
    top: 50%;
    left: -10px;
    width: 15px;
    height: 15px;
    background: #1653C3;
    border-radius: 50%;
    transform: translateY(-50%);
    box-shadow: 0 0 15px rgba(244, 163, 0, 0.5);
} */

.roadmapSubtitle {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: var(--text-color);
    text-transform: capitalize;
}

.roadmapText {
    font-size: 1.1rem;
    color: var(--text-color);
    font-weight: 400;
    line-height: 1.5;
}

@media (max-width: 1024px) {
    .roadmapContainer::before {
        left: 50%;
    }

    .roadmapItem {
        max-width: 90%;
    }
}

@media (max-width: 768px) {
    .roadmapContainer {
        width: 100%;
        padding: 0 0 2rem 0;
    }

    .heading {
        font-size: 2.5rem;
    }

    .subheading {
        font-size: 1rem;
        margin-bottom: 1rem;
    }

    .roadmapContainer::before {
        left: 20px;
    }

    .roadmapItem {
        flex-direction: column !important;
        text-align: left;
        align-items: flex-start;
        padding-left: 40px;
        min-width: 100%;
    }

    .roadmapIcon {
        margin: 10px 0;
    }

    .roadmapContent {
        max-width: 100%;
        text-align: left;
    }

    .roadmapContent::after {
        left: -8px;
        width: 12px;
        height: 12px;
    }
}

@media (max-width: 480px) {
    .heading {
        font-size: 2rem;
    }

    .subheading {
        font-size: 1rem;
        text-align: justify;
    }

    .roadmapSubtitle {
        font-size: 1.2rem;
    }

    .roadmapText {
        font-size: 1rem;
    }
}