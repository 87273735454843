.container {
  padding: 4rem 2rem;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  font-family: var(--font-primary);
  line-height: 1.8;
}

.heading {
  font-size: 3rem;
  text-align: center;
  font-weight: 700;
  color: var(--heading-color);
  margin-bottom: 2rem;
  font-family: var(--font-heading);
}

.jobGrid {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  justify-content: center;
  align-items: center;
}

.jobCard {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 360px;
  min-width: 320px;
  min-height: 460px;
  position: relative;
  padding-bottom: 60px;
  margin: auto;
}

.jobCardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.jobTitle {
  font-size: 24px;
  font-weight: 500;
}

.shareIcon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.jobDetails {
  display: flex;
  justify-content: space-between;
  color: var(--text-color);
  font-weight: 400;
  margin-bottom: 1rem;
  gap: 2px;
}

.jobDetails span {
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 4px;
}

.roleLabel {
  font-size: 0.9rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.roleDescription {
  font-size: 14px;
  text-align: justify;
  margin-bottom: 1.6rem;
  line-height: 1.5rem;
}

.progressContainer {
  margin-bottom: 2rem;
}

.progressBar {
  background: #ddd;
  height: 6px;
  border-radius: 4px;
  overflow: hidden;
}

.progressFill {
  background: #1a52e2;
  height: 100%;
}

.progressContainer span  {
  color: #000;
  font-size: 10px;
  font-weight: 400;
  text-align: left;
}

.applyButton {
  width: 60%;
  background: var(--button-bg);
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50px;
  cursor: pointer;
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
}

.applyButton:disabled {
  background: grey;
  cursor: not-allowed;
}

.applyButton:hover {
  background: var(--button-hover-bg);
}

.viewMoreContainer {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  align-items: center;
}

.viewMoreButton {
  background-color: #fff;
  color: var(--button-bg);
  border: 2px solid var(--button-bg);
  border-radius: var(--radius-md);
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.viewMoreButton:hover {
  background-color: var(--button-hover-bg);
  color: var(--background-color);
}

@media (max-width: 1024px) {
  .jobGrid {
    padding: 0 1rem;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 2.5rem;
  }

  .jobGrid {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 2rem 1rem;
  }

  .heading {
    font-size: 2rem;
  }

  .jobGrid {
    justify-content: center;
  }

  .jobCard {
    max-width: max-content;
    min-width: 200px;
  }

  .jobTitle {
    font-size: 1.25rem;
  }
}
