.footer {
  background: var(--imperial-blue);
  color: #ffffff;
  padding: 1rem 2rem;
  font-family: var(--font-primary);
}

.container {
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  padding: 0.5rem 2rem;
}

/* Footer Text */
.footer p {
  font-size: 0.9rem;
  color: var(--ivory-color);
  margin: 0;
  /* text-align: center; */
  flex: 1;
}

/* Footer Links */
.links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  list-style: none;
  padding: 0;
  margin: 0;
}

.links li {
  margin: 0;
  display: flex;
  align-items: center;
  position: relative;
}

/* Vertical Separator */
.links li:not(:last-child)::after {
  content: "";
  width: 1px;
  height: 15px;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  right: -12px;
}

/* Links Styling */
.links a {
  color: var(--ivory-color);
  text-decoration: none;
  font-size: 1rem;
  position: relative;
  transition: color 0.3s ease-in-out;
}

.links a:hover {
  color: #c3edff;
}

/* Social Icons */
.social {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.social a {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  color: var(--ivory-color);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.social a:hover {
  transform: translateY(-4px);
  box-shadow: 0px 8px 15px rgba(255, 111, 145, 0.5);
  background: linear-gradient(135deg, #dc5346 0%, #4d43ff 100%);
}

/* Tablet View */
@media (max-width: 992px) {
  .container {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
    padding-top: 1rem;
    gap: 1rem;
  }

  .links {
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
  }

  .links li:not(:last-child)::after {
    height: 10px;
    right: -8px;
  }

  .social {
    justify-content: center;
  }

  .footer p {
    flex: unset;
  }
}

/* Mobile View */
@media (max-width: 480px) {
  .links {
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
  }

  .links li:not(:last-child)::after {
    display: none;
  }

  .footer p {
    font-size: 0.8rem;
  }

  .social a {
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
  }
}
