.hero {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url("/public/assets/images/careers/hero.webp") no-repeat center center/cover;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-primary);
}

.overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.backButton {
  position: absolute;
  top: 1rem;
  left: 20px;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 8px 12px;
  transition: color 0.3s ease;
  z-index: 1;
}

.backButton span {
  margin-left: 0.4rem;
  font-size: 0.8rem;
}

.backButton:hover {
  text-decoration: none;
  cursor: pointer;
}

.backButton svg {
  margin-right: 8px;
}

.content {
  text-align: center;
  color: #fff;
  padding: 20px;
}

.heading {
  font-size: 4rem;
  font-weight: 500;
}

.highlight {
  font-weight: 700;
  color: #fcffdd;
}

.button {
  margin-top: 4rem;
  padding: 12px 24px;
  font-size: 1.2rem;
  color: #0056b3;
  background: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.button:hover {
  background: #e0e0e0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .heading {
    font-size: 2.5rem;
  }

  .button {
    font-size: 1rem;
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 1.8rem;
  }

  .button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}