.container {
  background-color: var(--primary-color);
  padding: 4rem;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: var(--font-primary);
  gap: 8rem;
}

.heading {
  font-size: 1.8rem;
  font-weight: 500;
  text-align: left;
  max-width: 40%;
  line-height: 1.4;
  color: var(--background-color);
}

.form {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  position: relative;
}

.input {
  flex: 1;
  max-width: 33%;
  padding: 0.8rem;
  border: none;
  border-bottom: 2px solid var(--background-color);
  background: transparent;
  color: var(--background-color);
  font-size: 1rem;
  border-radius: 0;
  outline: none;
  transition: border-color 0.3s ease;
}

.input::placeholder {
  color: var(--background-color);
  background-color: unset;
  opacity: 0.7;
}

.input:focus {
  border-color: #ccc;
}

.button {
  max-width: 33%;
  padding: 0.8rem 2rem;
  background-color: var(--background-color);
  color: var(--text-color);
  border: none;
  font-size: 1rem;
  border-radius: var(--radius-sm);
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.button:hover {
  background-color: #ccc;
  color: #000;
}

.successMessage {
  margin-top: 1rem;
  font-size: 1rem;
  color: var(--background-color);
  text-align: center;
}

@media (max-width: 1200px) {
  .heading {
    font-size: 1.25rem;
  }

  .container {
    padding: 4rem;
    gap: 4rem;
  }

}

@media (max-width: 992px) {
  .container {
    flex-direction: column;
    gap: 2rem;
  }

  .heading {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 1.8rem;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 1.5rem;
  }

  .form {
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  }

  .input {
    max-width: 100%;
    text-align: left;
    margin-bottom: 1rem;
  }

  .button {
    width: 100%;
    max-width: 50%;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 2rem 1rem;
  }

  .heading {
    font-size: 1.2rem;
  }

}