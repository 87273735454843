.container {
  padding: 4rem 2rem;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  font-family: var(--font-primary);
  line-height: 1.8;
}

.header {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 1rem;
}

.heading {
  font-size: 3rem;
  text-align: center;
  font-weight: 700;
  color: var(--heading-color);
  margin-bottom: 2rem;
  font-family: var(--font-heading);
}

.quoteBox {
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;
  max-width: 1400px;
  position: relative;
}

.quote {
  font-size: 2rem;
  font-style: italic;
  color: #2a5cdb;
  line-height: 1.6;
  position: relative;
  padding: 1rem 4rem;
  text-align: center;
}

.quoteMarkOpen,
.quoteMarkClose {
  font-size: 1.5rem;
  width: 60px;
  color: #2a5cdb;
  font-weight: bold;
}

.quoteMarkClose {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.quoteMarkOpen {
  position: absolute;
  top: 10px;
  left: 10px;
}

@media (max-width: 1024px) {
  .heading {
    font-size: 2rem;
  }

  .quote {
    font-size: 1.25rem;
  }

  .quoteBox {
    padding: 1.5rem;
  }

  .quoteMarkOpen,
  .quoteMarkClose {
    width: 50px;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 2.5rem;
  }

  .quote {
    font-size: 1.25rem;
    padding: 1rem 3rem;

  }

  .quoteBox {
    padding: 1rem;
  }

  .quoteMarkOpen,
  .quoteMarkClose {
    width: 40px;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 2rem 1rem;
  }

  .heading {
    font-size: 2rem;
  }

  .quoteBox {
    padding: 0;
  }

  .quote {
    font-size: 1rem;
    padding: 1rem 2rem;
  }

  .quoteMarkOpen,
  .quoteMarkClose {
    width: 20px;
  }
}