.hero {
  position: relative;
  height: 100vh;
  background: url('/public/assets/images/home/hero_gradient.png') no-repeat center center/cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  padding: 0 20px;
  font-family: var(--font-heading);
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(1, 41, 143, 0.1);
  z-index: 1;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  font-family: 'Rubik', serif;
}

.logoContainer {
  z-index: 1000;
}

.logo {
  font-size: 1.5rem;
  font-weight: 300;
  color: var(--background-color);
  font-family: var(--font-heading);
}

.navLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  list-style: none;
  margin-left: 2.5rem;
  z-index: 2000;
}

.navLinks li {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.navLinks a {
  color: var(--ivory-color);
  font-family: var(--font-button);
  text-decoration: none;
  transition: color 0.3s ease;
  text-transform: uppercase;
}

.navLinks a:hover {
  color: #ccc;
}

.joinButton {
  font-weight: 500;
  border: none;
  padding: 0.5rem 2rem;
  border-radius: var(--radius-xxl);
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s ease;
  color: var(--slate-gray);
  z-index: 2000;
  text-transform: uppercase;
}

.joinButton:hover {
  background: linear-gradient(135deg, #a1a3ff, #3633ff);
  color: var(--ivory-color);
}

.burgerMenu {
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.burgerOpen {
  transform: rotate(90deg);
}

.mobileNav {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100vh;
  background: var(--nav-bg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: left 0.4s ease-in-out;
  z-index: 1000;
  font-family: var(--font-primary);
}

.showMenu {
  left: 0;
}

.mobileNav ul {
  list-style: none;
  padding: 0;
  text-align: center;
}

.mobileNav li {
  margin: 20px 0;
}

.mobileNav a {
  color: var(--background-color);
  font-size: 1rem;
  text-decoration: none;
}

.mobileNav a:hover {
  color: #ccc;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  margin-top: 12rem;
}

.title {
  font-family: var(--font-heading);
  font-size: 8rem;
  font-weight: 400;
  margin-top: 10rem;
  color: var(--ivory-color);
}

.tagline {
  font-size: 3rem;
  font-family: var(--font-heading_tagline);
  font-weight: 400;
  color: var(--ivory-color);
}

.scrollIcon {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  animation: bounce 2s infinite;
  z-index: 999;
}

.iconText {
  color: var(--background-color);
  margin-bottom: 0.5rem;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(-50%) translateY(0);
  }
  40% {
    transform: translateX(-50%) translateY(-10px);
  }
  60% {
    transform: translateX(-50%) translateY(-5px);
  }
}

@media (max-width: 992px) {
  .title {
    font-size: 6rem;
  }
}
@media (max-width: 768px) {
  .title {
    font-size: 5.4rem;
  }

  .tagline {
    font-size: 2.5rem;
  }

  .navLinks {
    display: none;
  }

  .mobileNav a {
    color: var(--background-color);
  }
  
  .mobileNav a:hover {
    color: #ccc;
  }

  .navbar .joinButton {
    display: none;
  }

  .burgerMenu {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2000;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 4rem;
    line-height: 1.4;
    margin-bottom: 2rem;
  }
  
  .tagline {
    font-size: 1.5rem;
  }

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }
}