.container {
  padding: 6rem 2rem;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  font-family: var(--font-primary);
  line-height: 1.8;
}

.heading {
  font-size: 3rem;
  text-align: center;
  font-weight: 700;
  color: var(--heading-color);
  margin-bottom: 2rem;
  font-family: var(--font-heading);
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  justify-items: center;
  align-items: start;
  max-width: 1600px;
  margin: 0 auto;
}

.cardWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 250px;
}

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  width: 120px;
  height: 120px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 64px;
  color: #333;
}

.text {
  font-size: 1.1rem;
  color: var(--text-color);
  /* margin-bottom: 1rem; */
  font-weight: 600;
  text-align: center;
  font-family: var(--font-subheading);
  margin-top: 1rem;
}

.description {
  font-size: 1rem;
  color: var(--text-color);
  font-family: var(--font-primary);
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
}

@media (max-width: 768px) {
  .icon {
    font-size: 2.5rem;
  }
  .heading {
    font-size: 2.5rem;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 2rem 1rem;
  }

  .grid {
    gap: 1.5rem;
  }
  
  .heading {
    font-size: 2rem;
  }

  .text {
    margin-top: 1.5rem;
  }

  .description {
    font-size: 1rem;
  }

  .cardWrapper {
    max-width: 320px;
  }
}