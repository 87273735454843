/* Container */
.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #fff;
    color: #000;
    min-height: 100vh;
    position: relative;
    font-family: var(--font-primary);
    padding-bottom: 2rem;
}

.cover {
    background: #0056d2;
    color: #fff;
    text-align: left;
    position: relative;
    height: 300px;
    background: url('/public/assets/images/careers/hero.jpeg') no-repeat center scale-down;
}

.backButton {
    position: absolute;
    top: 1rem;
    left: 20px;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 8px 12px;
    transition: color 0.3s ease;
    z-index: 1;
}

.backButton span {
    margin-left: 0.4rem;
    font-size: 0.8rem;
}

.backButton:hover {
    text-decoration: none;
    cursor: pointer;
}

.backButton svg {
    margin-right: 8px;
}

.logoContainer {
    display: flex;
    flex-direction: column;
    padding: 1rem 3rem;
}

.logo {
    width: 60px;
    height: 60px;
    background-color: #d9d9d9;
    border-radius: 50%;
    margin-bottom: 10px;
}

.logoText {
    font-size: 1.2rem;
    font-weight: 500;
    padding-left: 4px;
    color: #000;
}

.detailsContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 3rem;
}

.header {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    width: 100%;
}

.details {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0;
    gap: 3rem;
    border-radius: 8px;
    width: 100%;
}

.detailItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    gap: 10px;
}

.title {
    font-size: 2rem;
    font-weight: 500;
}

.title span {
    font-size: 1.4rem;
    font-weight: 500;
}

.applyButtonContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.applyButton {
    background-color: #0056d2;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 0.6rem 2rem;
    /* min-width: 200px; */
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.applyButton:hover {
    background-color: #003da1;
}

.description {
    padding: 1rem 3rem;
    /* border: 1px solid #ccc; */
    border-radius: 10px;
    background: #fff;
    /* margin: 0 3rem; */
}

.description h3 {
    font-size: 1.25rem;
    margin-top: 20px;
    color: #000;
    /* text-transform: uppercase; */
    letter-spacing: 0.5px;
    font-weight: 400;
    /* Slight letter spacing */
}

.description p,
.description ul {
    font-size: 1rem;
    margin-top: 10px;
    color: #555;
}

.description ul {
    list-style: disc;
    margin-left: 20px;
}

.description ul li {
    margin-bottom: 10px;
    color: #555;
}

.mail {
    color: #0078e7;
    text-decoration: none;
}

@media (max-width: 992px) {
    .title {
        font-size: 1.25rem;
    }

    .logoContainer {
        padding: 1rem;
    }

    .details {
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
    }

    .detailsContainer {
        padding: 0 1rem;
    }

    .applyButtonContainer {
        justify-content: flex-start;
        align-items: flex-start;
    }

    .applyButton {
        margin-top: 1rem;
    }

    .benefits,
    .description {
        text-align: justify;
        padding: 1rem 1rem;
    }

    .backButton {
        top: 15px;
        left: 15px;
        font-size: 16px;
    }

    .detailsContainer {
        flex-direction: column;
    }

    .applyButton {
        padding: 0.4rem 1rem;
        font-size: 0.9rem;
    }
}