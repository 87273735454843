.container {
  font-family: 'Rubik', sans-serif;
  background-color: #fff;
  padding-bottom: 3rem;
}

.heroSection {
  text-align: center;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  min-height: 400px;
  justify-content: center;
  background: url('/public/assets/images/joinus.png') no-repeat center center/cover;
  /* max-width: 1200px;
  margin: 0 auto; */
}

.backButton {
  position: absolute;
  top: 1rem;
  left: 20px;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 8px 12px;
  transition: color 0.3s ease;
  z-index: 1;
}

.backButton span {
  margin-left: 0.4rem;
  font-size: 0.8rem;
}

.backButton:hover {
  text-decoration: none;
  cursor: pointer;
}

.backButton svg {
  margin-right: 8px;
}

.contentSection {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0.5rem 0rem 1rem 0rem;
}

.heroTitle {
  font-size: 72px;
  font-weight: 300;
  color: #fff;
}

.heroDescription {
  font-size: 20px;
  font-weight: 300;
  color: #000;
  margin-bottom: 3rem;
  text-align: center;
}

.optionsSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 40px;
}

.optionCard {
  flex: 1;
  min-width: 280px;
  /* max-width: 400px; */
  background: #f9f9f9;
  border: 2px solid #ddd;
  padding: 20px;
  border-radius: 50px;
  text-align: left;
  cursor: pointer;
  transition: border-color 0.3s, transform 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.optionCard.active {
  border: 2px solid #1653C3;
}

.radioInput {
  font-size: 4rem;
  display: block;
  transform: scale(2);
}

.radioInput:checked + .radioCircle {
  background-color: #1653C3;
}

.optionContent  {
  padding: 0rem 2rem;
}

.optionCard h2 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 10px;
}

.optionCard p {
  font-size: 1.1rem;
  color: #000;
  font-weight: 300;
}

.formWrapper {
  margin: 0 4rem;
}

.formSection {
  background: #f5f5f5;
  padding: 3rem;
  border-radius: 10px;
  /* width: 100%; */
  pointer-events: none;
  transition: opacity 0.3s ease;
  position: relative;
  margin: 0 auto;
  max-width: 1200px;
}

.formSection.activeForm {
  opacity: 1;
  pointer-events: all;
}

.formContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
}

.formColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.centerLine {
  width: 1px;
  background-color: #000;
  opacity: 0.3;
  height: 300px;
}

.inputGroup {
  flex: 1;
  width: 100%;
}

.inputGroup label {
  display: block;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 8px;
}

.phoneContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.countryCodeSelect {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  background: #fff;
  cursor: pointer;
}


.input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size: 1rem;
}

.submitButton {
  width: 100%;
  padding: 10px 0;
  background: #1653C3;
  color: #fff;
  border: none;
  border-radius: 20px;
  font-size: 1rem;
  cursor: pointer;
  max-width: 150px;
  margin: 3rem auto 0;
  text-align: center;
  display: block;
  transition: background 0.3s, opacity 0.3s;
}

.submitButton:hover {
  background: #133ca5;
}

.submitButton:disabled {
  background: #a1a1a1;
  cursor: not-allowed;
  opacity: 0.7;
}

/* .backButton {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 10px 15px;
  background: transparent;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s;
} */

@media (max-width: 1024px) {
  .heroTitle {
    font-size: 56px;
  }

  .heroDescription {
    font-size: 20px;
  }

  .formContainer {
    gap: 1.5rem;
  }
}
@media (max-width: 992px) {
  .optionsSection {
    flex-direction: column;
    align-items: center;
  }

  .centerLine {
    display: none;
  }
}
@media (max-width: 768px) {
  
  .formContainer {
    flex-direction: column;
    align-items: center;
  }

  .formColumn {
    width: 100%;
  }

  .formWrapper {
    margin: 0 2rem;
  }
  .formSection {
    padding: 2rem 1.5rem;
  }

  .submitButton {
    max-width: 100%;
  }

  .heroTitle {
    font-size: 48px;
  }

  .heroDescription {
    font-size: 18px;
  }

  .contentSection {
    padding: 0 2rem;
  }
}

@media (max-width: 480px) {
  .formSection {
    padding: 2rem 1rem;
  }
  .heroTitle {
    font-size: 36px;
  }
  .heroDescription {
    font-size: 18px;
    text-align: justify;
  }
  .optionCard h2 {
    font-size: 1.1rem;
  }

  .optionCard p {
    font-size: 0.9rem;
    text-align: justify;
  }

  .optionContent {
    padding: 0rem 0rem 0rem 1.5rem;
  }

  .radioInput {
    transform: scale(1.5);
  }
}