.container {
  width: 100%;
  font-family: var(--font-primary);
  line-height: 1.8;
  padding: 6rem 0;
}

.heading {
  font-size: 3rem;
  text-align: center;
  font-weight: 700;
  color: var(--heading-color);
  margin-bottom: 2rem;
  font-family: var(--font-heading);
}

.contentWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--imperial-blue);
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  min-height: 400px;
}

.textWrapper {
  flex: 1.2;
  margin-right: 20px;
  color: #ffffff;
  padding: 3rem;
  display: flex;
  flex-direction: column;
}

.description {
  font-size: 1.1rem;
  line-height: 4rem;
  font-weight: 300;
  margin-bottom: 20px;
  text-align: center;
  color: var(--ivory-color);
}

.highlight {
  font-weight: bold;
}

.button {
  background-color: transparent;
  border: 2px solid var(--background-color);
  border-radius: var(--radius-lg);
  padding: 8px 16px;
  color: var(--background-color);
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
  width: 160px;
  margin: 0 auto;
  text-transform: uppercase;
}

.button:hover {
  background-color: var(--background-color);
  color: var(--button-bg);
}

.image {
  max-height: 420px;
  width: 100%;
  height: 100%;
  clip-path: polygon(25% 0, 100% 0, 100% 100%, 0% 100%);
  object-fit: cover;
  flex-shrink: 0;
  flex: 0.8;
}

@media (max-width: 1200px) {
  .textWrapper {
    padding: 2rem;
    flex: 1.5;
  }

  .description {
    font-size: 1rem;
    line-height: 2rem;
  }

  .button {
    padding: 10px;
    font-size: 1rem;
  }

  .image {
    flex: 0.5;
    max-height: 400px;
  }
}

@media (max-width: 920px) {
  .contentWrapper {
    flex-direction: column-reverse;
    align-items: center;
  }

  .textWrapper {
    margin-right: 0;
    margin-bottom: 20px;
    padding: 2rem 1rem;
  }

  .description {
    font-size: 1rem;
    line-height: 3rem;
    font-weight: 400;
  }

  .image {
    width: 100%;
    height: auto;
    clip-path: none;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 2.5rem;
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 2rem;
  }
}

