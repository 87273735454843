.container {
    padding: 4rem 2rem;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    font-family: var(--font-primary);
    line-height: 1.8;
}

.heading {
    font-size: 3rem;
    text-align: center;
    font-weight: 700;
    color: var(--heading-color);
    margin-bottom: 2rem;
    font-family: var(--font-heading);
}

.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    justify-items: center;
    background: #fff;
    margin: 0 auto;
    padding: 4rem 2rem;
    border-radius: 10px;
}

.card {
    border-radius: 8px;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    text-align: left;
    gap: 1.5rem;
    width: 100%;
    background-color: #f7f7f7;
    transition: transform 0.3s, box-shadow 0.3s;
}

.icon {
    width: 48px;
    height: 48px;
}

.text {
    font-size: 1.1rem;
    color: var(--text-color);
    line-height: 1.5;
    font-weight: 400;
}

@media (max-width: 992px) {
    .container {
        padding: 2rem 1rem;
    }
    
    .grid {
        gap: 1rem;
        padding: 1rem 2rem;
        grid-template-columns: repeat(1, 1fr);
    }

    .card {
        align-items: flex-start;
        text-align: left;
    }

    .icon {
        width: 50px;
        height: 50px;
    }
}

@media (max-width: 768px) {
    .heading {
        font-size: 2.5rem;
    }
}

@media (max-width: 480px) {
    .container {
        padding: 2rem 1rem;
    }

    .heading {
        font-size: 2rem;
    }

    .grid {
        padding: 0;
    }

    .card {
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;
        background-color: #f9f9f9;
    }

    .text {
        font-size: 1rem;
    }
}